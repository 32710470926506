<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div>
          <router-link to="/skip-request-listing">
            <button class="btn btn-success btn btn-success rounded-pill mb-2">
              Back
            </button>
          </router-link>
        </div>
        <div class="col-12 mt-2">
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>
          <div></div>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Edit Sharing Request</h4>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-success"
            >
              {{ alertText }}
            </div>
            <b-form-group
              id="input-group-3"
              label="Skip Id:"
              label-for="input-3"
            >
              <b-form-select
                v-model="skipId"
                class="mb-3"
                required
                @change="onChangeSkip()"
              >
                <option
                  v-for="skip in skips"
                  :value="skip.id"
                  v-bind:key="skip.id"
                  >{{ skip.id }}</option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Customer:"
              label-for="input-3"
            >
              <multiselect
                track-by="id"
                :custom-label="customLabel"
                label="username"
                v-model="userId"
                :options="users"
                placeholder="Select customer"
                @select="onSelectCustomer"
                @input="onChange"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.username }}({{ option.email }})</strong
                  ></template
                >
              </multiselect>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Garbage:"
              label-for="input-3"
            >
              <b-form-select
                @change="onChangeGarbageSpace()"
                id="input-3"
                v-model="garbage_space"
                :options="sizes"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="ZipCode:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="zipcode"
                type="text"
                required
                placeholder="Enter ZipCode"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Estimated Price:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="estimatedPrice"
                type="text"
                readonly
                placeholder="Estimated Price"
              ></b-form-input>
            </b-form-group>

            <!-- <b-form-group
                id="input-group-2"
                label="Expiry Date:"
                label-for="input-2"
              >
                <b-form-datepicker
                  v-model="expirydate"
                  :min="new Date().toISOString().substr(0, 10)"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group> -->

            <b-form-group
              id="input-group-2"
              label="Detail:"
              label-for="input-2"
            >
              <b-form-textarea
                id="textarea"
                v-model="detail"
                placeholder="Enter description here..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>
      <br /><br />
      <div class="card">
        <div class="col-12 mt-2">
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <br />
          <b-form-group
            id="input-group-2"
            label="Upload Images:"
            label-for="input-2"
          >
            <input
              type="file"
              ref="file"
              multiple="multiple"
              @change="submitFiles"
            />
          </b-form-group>

          <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
            <b-row>
              <b-col-4
                v-for="(image, key) in imagesArray"
                :key="key"
                class="p-3"
              >
                <button
                  style="color:white"
                  class="close ml-1"
                  @click.prevent="removeImage(image)"
                >
                  &times;
                </button>
                <b-img
                  style="height: 150px;max-width: 165px;"
                  thumbnail
                  fluid
                  :src="getLogo(image)"
                  alt="Image 1"
                ></b-img>
              </b-col-4>
            </b-row>
          </b-container>

          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Images</h4>
          </div>
        </div>

        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="loans"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(image_name)="loans">
                <b-img
                  style="height: 70px;width: 72px;border-radius: 50%;"
                  thumbnail
                  fluid
                  :src="getLogo(loans.item.image_name)"
                  alt="Image 1"
                ></b-img>
              </template>

              <template v-slot:cell(actions)="loans">
                <button
                  v-bind:class="'btn btn-danger btn-sm'"
                  @click="deleteUser(loans)"
                >
                  Delete</button
                >&nbsp;
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
          <b-button ref="save" @click="onSubmit()" variant="primary"
            >Save</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import ApiService from "@/core/services/api.service";
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import VerficationModal from "./VerficationModal";
export default {
  components: {
    VerficationModal,
    Multiselect,
  },
  computed: {
    rows() {
      return this.loans.length;
    },
  },
  data() {
    return {
      selected: null,
      id: "",
      estimatedPrice: 0,
      username: "",
      orginalPrice: 0,
      loans: [],
      currentPage: 1,
      skipUserId: null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "id",
      filterOn: [],
      fields: [
        { key: "image_name", label: "Image", sortable: true },
        { key: "actions", sortable: true },
      ],
      imagesArray: [],
      skipId: null,
      imagesShow: false,
      users: [],
      userId: null,
      sortDesc: true,
      skips: [],
      user: null,
      isShowModel: false,
      detail: "",
      alertText: "",
      alert: false,
      garbage_space: null,
      consumedSpace: 0,
      actualSpace: 0,
      expirydate: "",
      sizes: [
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55",
        "60",
        "65",
        "70",
        "75",
        "80",
        "85",
        "90",
        "95",
        "100",
      ],
      zipcode: "",
      show: true,
    };
  },

  mounted() {
    this.getUsers();
    this.getSkips();
    this.editRecord = JSON.parse(localStorage.getItem("skipRequestEdit"));
    this.loans = this.editRecord.images;
    this.id = this.editRecord.id;
    this.garbage_space = this.editRecord.garbage_space;
    this.userId = this.editRecord.requestUserDetail;
    this.skipId = this.editRecord.skip_to_share_id;
    this.consumedSpace = this.editRecord.skipdetail.consumed_space;
    this.actualSpace = this.editRecord.skipdetail.space;
    this.skipUserId = this.editRecord.skipdetail.userDetail.id;
    this.zipcode = this.editRecord.requestUserDetail.zipcode;
    this.detail = this.returnEmptyValue(this.editRecord.detail);
    this.expirydate = this.editRecord.expiry_date;
    this.description = this.editRecord.description;
    this.instruction = this.editRecord.instruction;
    this.selected = this.editRecord.requestUserDetail.id;
    this.estimatedPrice = this.editRecord.estimated_price;
  },
  methods: {
    onSelectCustomer(option) {
      this.username = option.username;
    },
    onChangeGarbageSpace() {
      let garbagePercentageCalculation = parseFloat(
        ((this.orginalPrice / 100) * this.garbage_space).toFixed(2)
      );
      this.estimatedPrice = garbagePercentageCalculation;
    },
    onChangeSkip() {
      let skipId = this.skipId;
      var valObj = this.skips.filter(function(elem) {
        if (elem.id == skipId) return elem;
      });
      if (valObj.length > 0) {
        this.skipUserId = valObj[0].userDetail.id;
        this.estimatedPrice = valObj[0].price;
        this.orginalPrice = valObj[0].price;
      }
    },
    returnEmptyValue(value) {
      if (value == "null") {
        return (value = "");
      } else {
        return value;
      }
    },
    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },
    onChange({ zipcode }) {
      this.zipcode = zipcode;
    },
    onSubmit() {
      try {
        const formData = new FormData();
        if (this.userId == null) {
          this.alert = true;
          this.alertText = "Customer is Required";
          return false;
        }

        if (this.skipId == null) {
          this.alert = true;
          this.alertText = "Skip is Required";
          return false;
        }
        if (this.expirydate == "") {
          this.alert = true;
          this.alertText = "Expiry Date is required";
          return false;
        }
        let userId;
        if (this.userId.id) {
          userId = this.userId.id;
        } else {
          userId = this.userId.user_id;
        }

        if (this.skipUserId == userId) {
          this.alert = true;
          this.alertText = "Skip owner can not request on his own skip";
          return false;
        }

        if (this.imagesArray.length < 2 && this.loans.length < 2) {
          this.alert = true;
          this.alertText = "At Least 2 images are mandatory";
          return false;
        }
        let totlaSize = this.garbage_space + this.consumedSpace;
        let remainingSpace = this.actualSpace - this.consumedSpace + "%";

        if (totlaSize > this.actualSpace) {
          this.alert = true;
          this.alertText =
            "There is only " +
            remainingSpace +
            " space available against this skip";
          return false;
        }
        this.addLoaderClasses("save");
        formData.append("id", this.id);
        if (this.userId.id) {
          formData.append("user_id", this.userId.id);
        } else {
          formData.append("user_id", this.userId.user_id);
        }
        formData.append("skip_id", this.skipId);
        formData.append("garbage_space", this.garbage_space);
        formData.append("zipcode", this.zipcode);
        formData.append("username", this.userId.username);
        formData.append("detail", this.detail);
        formData.append("expirydate", this.expirydate);
        formData.append("estimated_price", this.estimatedPrice);
        formData.append("images", JSON.stringify(this.imagesArray));
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-request-store", formData, {
          // headers,
        })
          .then((response) => {
            if (response.data.code == 200) {
              localStorage.setItem(
                "alertmessage",
                JSON.stringify("Record has been updated successfully")
              );
              this.$router.push({ name: "skip-request-listing" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            response.data.data.map((x) => {
              this.imagesArray.push(x);
            });
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },
    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    deleteUser(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete-request-image";
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    deleteRow(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        // this.loans.splice(index, 1);
        this.editRecord.images.splice(index, 1);
        this.loans = this.editRecord.images;
        localStorage.removeItem("skipRequestEdit");
        localStorage.setItem(
          "skipRequestEdit",
          JSON.stringify(this.editRecord)
        );
      }

      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    getUsers() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getSkips() {
      try {
        axios.get(this.$path + "/skip-share-list-admin").then((response) => {
          this.skips = response.data.data;
          // console.log(this.skips);
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}
</style>
